import React, { useState } from 'react';
import PropTypes from 'prop-types';
import handshake from '../images/handshake.jpg';
import redclock from '../images/redclock.jpg';
import loadedhauler from '../images/loadedhauler.jpg';

const Features = ({
  feature1Title,
  feature1Description,
  feature1ImgSrc,
  feature1ImgAlt,
  feature2Title,
  feature2Description,
  feature2ImgSrc,
  feature2ImgAlt,
  feature3Title,
  feature3Description,
  feature3ImgSrc,
  feature3ImgAlt,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const features = [
    {
      title: feature1Title,
      description: feature1Description,
      imgSrc: feature1ImgSrc,
      imgAlt: feature1ImgAlt,
    },
    {
      title: feature2Title,
      description: feature2Description,
      imgSrc: feature2ImgSrc,
      imgAlt: feature2ImgAlt,
    },
    {
      title: feature3Title,
      description: feature3Description,
      imgSrc: feature3ImgSrc,
      imgAlt: feature3ImgAlt,
    },
  ];

  return (
    <div className="py-12 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Tabs Menu */}
        <div className="flex flex-col gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              onClick={() => setActiveTab(index)}
              className={`flex items-start gap-4 cursor-pointer ${
                activeTab === index
                  ? 'bg-gray-100 border-l-4 border-blue-500'
                  : 'hover:bg-gray-50'
              } p-4 rounded-md transition`}
            >
              <div
                className={`w-1 ${
                  activeTab === index ? 'bg-blue-500' : 'bg-transparent'
                }`}
              ></div>
              <div>
                <h2 className="text-lg font-semibold">{feature.title}</h2>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Container */}
        <div className="relative h-96 flex justify-center items-center">
          <img
            alt={features[activeTab].imgAlt}
            src={features[activeTab].imgSrc}
            className="w-full h-full object-cover rounded-lg shadow-lg transition-opacity duration-300"
          />
        </div>
      </div>
    </div>
  );
};

Features.defaultProps = {
  feature1Title: 'Affordable Transportation Solutions',
  feature1Description:
    'We offer cost-effective transportation solutions for both short and long distances, catering to the needs of businesses and individuals alike.',
  feature1ImgSrc: loadedhauler,
  feature1ImgAlt: 'Affordable Transportation Solutions Image Alt',
  feature2Title: 'Quality Service Guaranteed',
  feature2Description:
    'At TayMar J Transportation, we are committed to providing top-notch service to our customers, ensuring a smooth and reliable transportation experience.',
  feature2ImgSrc: handshake ,
  feature2ImgAlt: 'Quality Service Guaranteed Image Alt',
  feature3Title: 'Customer Satisfaction',
  feature3Description:
    'Our priority is customer satisfaction, and we strive to exceed expectations by delivering exceptional service and personalized attention.',
  feature3ImgSrc: redclock,
  feature3ImgAlt: 'Customer Satisfaction Image Alt',
};

Features.propTypes = {
  feature1Title: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2Description: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature3Title: PropTypes.string,
  feature3Description: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
};

export default Features;
