import React from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import carwashImg from '../images/carwash.jpg';
import detailingImg from '../images/detailing.jpg';


const TayMarDetailing = () => {
    return (
        <div className="min-h-screen pt-[15vh] bg-gradient-to-r from-blue-900 to-gray-400 flex items-center justify-center p-4">
            <div className="max-w-5xl w-full bg-white rounded-lg shadow-xl p-8">
                {/* Header */}
                <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">
                    TMJ Auto Spa Special Promotion
                </h1>
                <p className="text-gray-700 mb-6 text-center text-lg">
                    <strong>Attention Burlington County and surrounding areas!</strong> Experience the convenience and savings with TMJ Auto Spa. We come to you delivering top-notch mobile detailing and car wash services. Check out our promotions below and take advantage of our special deals!
                </p>

                {/* Single Service and Add-Ons Card */}
                <div className="bg-gray-300 rounded-lg shadow-md p-6 mb-10">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                        Single Service + Add-Ons
                    </h2>
                    {/* Split Single Service Card */}
                    <div className="mb-6 text-center bg-white rounded p-4 shadow flex flex-col items-center justify-center gap-4 relative">
                        {/* Left Column: Mobile Car Wash */}
                        <div className="flex justify-center gap-4 items-center mt-2">
                            <span className="text-xl font-bold text-gray-500 line-through">$40</span>
                            <span className="text-xl font-bold text-green-600">$30 Promo Offer</span>
                        </div>
                        <div className="flex-1">
                            <p className="text-lg text-gray-800 font-semibold">Mobile Car Wash<p className="text-sm text-grey-400">(Includes tires, windows, and lights)</p></p>
                            <img src={carwashImg} alt="Car Wash" className="w-[50vw] mx-auto mt-4 rounded-lg shadow-md" />
                        </div>
                        {/* Vertical Divider (visible on md and larger screens) */}
                        <div className="text-grey-800 text-lg">OR</div>
                        {/* Right Column: Mobile Detailing */}
                        <div className="flex-1 pb-[5vh]">
                            <p className="text-lg text-gray-800 font-semibold">Interior Detailing<p className="text-sm text-grey-200">(Includes vaccum, windows, surfaces & screens)</p></p>
                            <img src={detailingImg} alt="Car Wash" className="w-[50vw] max-h-[25vh] h-auto aspect-video object-cover mx-auto mt-4 rounded-lg shadow-md" />
                        </div>
                    </div>
                    {/* Add-Ons Section */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 mb-2">Available Add-Ons</h3>
                        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-gray-700">
                            <li className="bg-white rounded p-4 shadow text-center">
                                Pet Hair Removal<br />
                                <span className="text-gray-500 line-through">$20</span> <span className="text-green-600 font-bold">$10 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Graphene Glass Coat<br />
                                <span className="text-gray-500 line-through">$40</span> <span className="text-green-600 font-bold">$20 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                RainX and Rim Protector<br />
                                <span className="text-gray-500 line-through">$40</span> <span className="text-green-600 font-bold">$20 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Seat Cleaning<br />
                                <span className="text-gray-500 line-through">Starting at $20</span> <span className="text-green-600 font-bold">$10 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Interior Condition & Protect<br />
                                <span className="text-gray-500 line-through">$10</span> <span className="text-green-600 font-bold">$5 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Tire Shine<br />
                                <span className="text-gray-500 line-through">$10</span> <span className="text-green-600 font-bold">$5 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Wax & Buff Shine<br />
                                <span className="text-gray-500 line-through">$40</span> <span className="text-green-600 font-bold">$25 Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center">
                                Air Freshener<br />
                                <span className="text-gray-500 line-through">$5</span> <span className="text-green-600 font-bold">FREE Promo Offer</span>
                            </li>
                            <li className="bg-white rounded p-4 shadow text-center col-span-full">
                                <strong>MOAM(Mother.Of.All.Messes)</strong><br />
                                <span className="text-gray-700 text-sm">For major stains, sticky substances, lingering odors, etc</span><br />
                                <span className="text-xl font-bold text-green-600"><span className="text-gray-500 line-through">+$60 </span>&nbsp; +$45</span>
                            </li>
                        </ul>
                    </div>
                </div>


                {/* Service Comparison Chart */}
                <div className="overflow-x-auto mb-10">
                    <table className="min-w-full bg-white rounded-xl overflow-hidden shadow-md text-sm">
                        <thead className="bg-gray-300 text-gray-800">
                            <tr>
                                <th className="py-4 px-6 text-left">Packages</th>
                                <th className="py-4 px-6 text-center">Value<br /><span className="line-through text-gray-500">$75</span> <span className="text-green-600 font-bold">$60</span></th>
                                <th className="py-4 px-6 text-center">Premium<br /><span className="line-through text-gray-500">$95</span> <span className="text-green-600 font-bold">$75</span></th>
                                <th className="py-4 px-6 text-center">Luxury<br /><span className="line-through text-gray-500">$125</span> <span className="text-green-600 font-bold">$100</span></th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {[
                                { label: 'Exterior Wash + Dry', values: [true, true, true] },
                                { label: 'Interior Vacuum + surface cleaning', values: [true, true, true] },
                                { label: 'Window Cleaning', values: [true, true, true] },
                                { label: 'Rim & Tire Shine', values: [false, true, true] },
                                { label: 'Rain-X / Rim Protectant', values: [false, true, true] },
                                { label: 'Interior Conditioning & Protectant', values: [false, false, true] },
                                { label: 'Hand Wax + Buff', values: [false, false, true] },
                                { label: '$3 Add-Ons', values: [false, false, true] }
                            ].map((row, idx) => (
                                <tr key={idx} className="border-t border-gray-200">
                                    <td className="py-3 px-6 font-medium">{row.label}</td>
                                    {row.values.map((val, i) => (
                                        <td key={i} className="text-center">{val ? <CheckCircle className="text-green-500 inline w-5 h-5" /> : <XCircle className="text-red-400 inline w-5 h-5" />}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Subscription Plans */}
                <div className="bg-gray-100 rounded-xl shadow-md p-6 mb-10">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">Subscription Plans</h2>
                    <p className="text-gray-700 mb-6">Choose a plan that fits your car care needs. Each tier includes options to pay monthly, quarterly, or yearly.</p>

                    <div className="grid md:grid-cols-3 gap-6 text-center text-gray-800">

                        {/* Value Tier */}
                        <div className="border border-gray-300 rounded-xl p-6 bg-white hover:shadow-lg transition">
                            <p className="font-bold text-xl mb-1">Value</p>
                            <p className="text-xs text-gray-500">starting at</p>
                            <p className="text-3xl font-extrabold text-green-600 mb-2">$250/mo </p>
                            <p className="text-xl font-bold text-green-600 mb-2">($50 savings)</p>
                            <ul className="text-sm text-gray-700 space-y-1 mb-4">
                                <li>Includes : </li>
                                <li>1 wash/week or 4 per month </li>
                                <li>Extra washes: $10 each</li>
                                <li>Quarterly: $700 ($200 savings)</li>
                                <li>Yearly: $2500 ($800 savings)</li>
                            </ul>
                            <p className="text-xs text-gray-500">Basic upkeep for budget-friendly care</p>
                        </div>

                        {/* Premium Tier */}
                        <div className="border-2 border-yellow-400 rounded-xl p-6 bg-yellow-50 hover:shadow-lg transition">
                            <p className="font-bold text-xl mb-1">Premium</p>
                            <p className="text-xs text-gray-500">starting at</p>
                            <p className="text-3xl font-extrabold text-yellow-500 mb-2">$300/mo</p>
                            <p className="text-xl font-bold text-green-600 mb-2">($80 savings)</p>
                            <ul className="text-sm text-gray-700 space-y-1 mb-4">
                                <li>1 wash/week + interior clean</li>
                                <li>Extra washes: $5 each</li>
                                <li>Quarterly: $850 ($290 savings)</li>
                                <li>Yearly: $3,250 ($1,310 savings</li>
                            </ul>
                            <p className="text-xs text-gray-500">Upgraded care & better value</p>
                        </div>

                        {/* Luxury Tier */}
                        <div className="border-4 border-green-500 rounded-xl p-6 bg-green-50 hover:shadow-xl transition transform scale-105">
                            <p className="font-bold text-xl mb-1">Luxury</p>
                            <p className="text-xs text-gray-500">starting at</p>
                            <p className="text-3xl font-extrabold text-green-700 mb-2">$400/mo</p>
                            <p className="text-xl font-bold text-green-600 mb-2">($100 savings)</p>
                            <ul className="text-sm text-gray-700 space-y-1 mb-4">
                                <li>Weekly Luxury Package (interior & exterior)</li>
                                <li>Extra washes: $2 each</li>
                                <li>Quarterly: $1,150 ($350 savings)</li>
                                <li>Yearly: $4,500 ($1,500 savings)</li>
                            </ul>
                            <p className="text-xs text-green-700 font-semibold">Top-tier experience & maximum savings</p>
                        </div>

                    </div>
                    <div className='py-[5vh] text-center'><p>Custom Budget plans are also available starting at $80/month - Request through our management team upon scheduling an appointment and a team member will walk you through creating a plan that works for you</p></div>
                </div>


                {/* Why Choose Us */}
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Why Choose TMJ Auto Spa?</h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
                    <li>Convenient, on-location service that saves you time and hassle.</li>
                    <li>Professional detailing that protects and enhances your vehicle’s appearance.</li>
                    <li>Transparent pricing with customizable service options.</li>
                    <li>Hassle-free signup – just call or email with your details and you're scheduled!</li>
                </ul>
                <div className="text-center">
                    <a href='/detailquote'>
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded shadow-lg transition duration-200">
                            Book Your Appointment Now
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TayMarDetailing;
