import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DataTrackerApp = () => {
  const [mainTitle, setMainTitle] = useState("");
  const [rows, setRows] = useState([
    { id: 1, title: "", values: [""], manualTotal: "" },
  ]);
  const [columnHeaders, setColumnHeaders] = useState(["Title", "Value 1"]);

  // Totals option state
  const [includeTotalRow, setIncludeTotalRow] = useState(false);
  const [totalRowLabel, setTotalRowLabel] = useState("Totals");

  const maxValues = Math.max(...rows.map((row) => row.values.length));

  useEffect(() => {
    const currentColumns = columnHeaders.length - 1;
    if (maxValues > currentColumns) {
      const newHeaders = [...columnHeaders];
      for (let i = currentColumns + 1; i <= maxValues; i++) {
        newHeaders.push(`Value ${i}`);
      }
      setColumnHeaders(newHeaders);
    }
  }, [maxValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMainTitleChange = (e) => {
    setMainTitle(e.target.value);
  };

  const handleRowTitleChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, title: value } : row))
    );
  };

  const handleHeaderChange = (index, newValue) => {
    setColumnHeaders((prev) => {
      const newHeaders = [...prev];
      newHeaders[index] = newValue;
      return newHeaders;
    });
  };

  const removeColumnHeader = (index) => {
    if (index === 0 || columnHeaders.length <= 2) return;
    setColumnHeaders((prev) => prev.filter((_, i) => i !== index));
    setRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        values: row.values.filter((_, i) => i !== index - 1),
      }))
    );
  };

  const handleValueChange = (rowId, valueIndex, newValue) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              values: row.values.map((val, idx) =>
                idx === valueIndex ? newValue : val
              ),
            }
          : row
      )
    );
  };

  // New: Handler for updating the manual total
  const handleManualTotalChange = (rowId, newValue) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, manualTotal: newValue } : row
      )
    );
  };

  const addValueToRow = (rowId) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, values: [...row.values, ""] } : row
      )
    );
  };

  const removeValueFromRow = (rowId, valueIndex) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              values: row.values.filter((_, idx) => idx !== valueIndex),
            }
          : row
      )
    );
  };

  // When adding a new row, copy the previous row's number of values and initialize manualTotal.
  const addNewRow = () => {
    setRows((prevRows) => {
      const lastRow = prevRows[prevRows.length - 1];
      const newRowValues = lastRow ? [...lastRow.values] : [""];
      return [
        ...prevRows,
        {
          id: prevRows.length + 1,
          title: "",
          values: newRowValues,
          manualTotal: ""
        },
      ];
    });
  };

  const deleteRow = (rowId) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
  };

  const exportToPDF = () => {
    const input = document.getElementById("document-preview");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("document.pdf");
    });
  };

  // Compute grand total based on the manual totals entered by the user.
  const computeGrandTotal = () => {
    return rows.reduce((acc, row) => {
      const num = parseFloat(row.manualTotal);
      return !isNaN(num) ? acc + num : acc;
    }, 0);
  };

  return (
    <div className="p-8 bg-white min-h-screen font-['Comic_Neue']">
      <h1 className="text-3xl font-bold mb-8 text-[#1e42ee] text-center">
        ⚡ Data Matrix Builder
      </h1>

      {/* Document Title Input */}
      <div className="max-w-4xl mx-auto mb-8">
        <label className="block text-gray-800 text-lg font-bold mb-3">
          Document Title:
        </label>
        <input
          type="text"
          value={mainTitle}
          onChange={handleMainTitleChange}
          className="w-full p-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
          placeholder="Enter main title"
        />
      </div>

      {/* Column Headers Input */}
      <div className="max-w-4xl mx-auto mb-8">
        <label className="block text-gray-800 text-lg font-bold mb-3">
          Column Headers:
        </label>
        <div className="flex flex-wrap gap-4 items-center">
          {columnHeaders.map((header, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="text"
                value={header}
                onChange={(e) => handleHeaderChange(index, e.target.value)}
                className="p-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
                placeholder={`Header ${index + 1}`}
              />
              {index > 0 && (
                <button
                  onClick={() => removeColumnHeader(index)}
                  className="px-2 py-1 text-red-500 hover:text-red-700"
                >
                  ×
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Totals Option */}
      <div className="max-w-4xl mx-auto mb-8">
        <label className="block text-gray-800 text-lg font-bold mb-3">
          Include Totals Row:
        </label>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={includeTotalRow}
            onChange={(e) => setIncludeTotalRow(e.target.checked)}
            className="mr-2"
          />
          <span>Yes</span>
        </div>
        {includeTotalRow && (
          <div className="mt-4">
            <label className="block text-gray-800 text-lg font-bold mb-3">
              Totals Row Label:
            </label>
            <input
              type="text"
              value={totalRowLabel}
              onChange={(e) => setTotalRowLabel(e.target.value)}
              className="w-full p-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
              placeholder="Enter label for totals row"
            />
          </div>
        )}
      </div>

      {/* Rows Input */}
      <div className="max-w-4xl mx-auto space-y-4 mb-8">
        {rows.map((row) => (
          <div
            key={row.id}
            className="flex flex-col gap-4 p-4 bg-gray-50 rounded-xl border border-gray-200"
          >
            <div className="flex gap-4 items-center">
              <input
                type="text"
                value={row.title}
                onChange={(e) => handleRowTitleChange(row.id, e.target.value)}
                className="flex-1 p-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
                placeholder="Row title"
              />
              <button
                onClick={() => deleteRow(row.id)}
                className="px-3 py-2 bg-gray-800 text-white rounded-lg hover:bg-[#1e42ee] transition-colors shadow-md"
              >
                ❌ Remove Row
              </button>
            </div>
            <div className="flex flex-wrap gap-4 items-center">
              {row.values.map((value, valueIndex) => (
                <div key={valueIndex} className="flex gap-2 items-center">
                  <input
                    type="text"
                    value={value}
                    onChange={(e) =>
                      handleValueChange(row.id, valueIndex, e.target.value)
                    }
                    className="p-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
                    placeholder={`${
                      columnHeaders[valueIndex + 1] || `Value ${valueIndex + 1}`
                    }`}
                  />
                  {row.values.length > 1 && (
                    <button
                      onClick={() => removeValueFromRow(row.id, valueIndex)}
                      className="px-2 py-1 bg-gray-200 text-gray-800 rounded-lg hover:bg-[#1e42ee] hover:text-white transition-colors"
                    >
                      ➖
                    </button>
                  )}
                  {valueIndex === row.values.length - 1 && (
                    <button
                      onClick={() => addValueToRow(row.id)}
                      className="px-2 py-1 bg-gray-200 text-gray-800 rounded-lg hover:bg-[#1e42ee] hover:text-white transition-colors"
                    >
                      ➕
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Control Buttons */}
      <div className="max-w-4xl mx-auto flex gap-4 mb-12">
        <button
          onClick={addNewRow}
          className="px-6 py-3 bg-[#1e42ee] text-white rounded-lg hover:bg-gray-800 transition-colors shadow-md flex items-center gap-2"
        >
          ➕ Add New Row
        </button>
        <button
          onClick={exportToPDF}
          className="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-[#1e42ee] transition-colors shadow-md flex items-center gap-2"
        >
          📤 Export PDF
        </button>
      </div>

      {/* Document Preview */}
      <div
        id="document-preview"
        className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-lg border-2 border-gray-200 font-['Roboto']"
      >
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 border-b-2 border-[#1e42ee] pb-4">
          {mainTitle || "Your Document Title"}
        </h2>
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              {columnHeaders.map((header, index) => (
                <th
                  key={index}
                  className="text-left py-4 px-4 font-semibold text-gray-800"
                >
                  {header}
                </th>
              ))}
              {includeTotalRow && (
                <th className="text-left py-4 px-4 font-semibold text-gray-800">
                  Amount
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50 even:bg-gray-50">
                <td className="py-3 px-4 border-b border-gray-200">
                  {row.title}
                </td>
                {[...Array(maxValues)].map((_, index) => (
                  <td key={index} className="py-3 px-4 border-b border-gray-200">
                    {row.values[index] || "-"}
                  </td>
                ))}
                {includeTotalRow && (
                  <td className="py-3 px-4 border-b border-gray-200">
                    <input
                      type="text"
                      value={row.manualTotal}
                      onChange={(e) =>
                        handleManualTotalChange(row.id, e.target.value)
                      }
                      className="w-full p-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-[#1e42ee] focus:border-transparent transition-all"
                      placeholder="Enter total"
                    />
                  </td>
                )}
              </tr>
            ))}
            {includeTotalRow && (
              <tr className="bg-gray-100 font-bold">
                {columnHeaders.map((header, index) => {
                  if (index === 0) {
                    return (
                      <td
                        key={index}
                        className="py-3 px-4 border-t border-gray-200"
                      >
                        {totalRowLabel || "Totals"}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={index}
                        className="py-3 px-4 border-t border-gray-200"
                      >
                        -
                      </td>
                    );
                  }
                })}
                {/* Grand total for the "Row Total" column */}
                <td className="py-3 px-4 border-t border-gray-200">
                  {computeGrandTotal()}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTrackerApp;
