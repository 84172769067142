import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PickupDatesForm = () => {
  const location = useLocation();
  const { formData, distance, price, orderCode } = location.state;
  const [pickupDate, setPickupDate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...formData,
      distance,
      price,
      orderCode,
      pickupDate,
    };

    try {
      const response = await fetch('YOUR_API_ENDPOINT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Your order has been submitted successfully!');
      } else {
        alert('Failed to submit order. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  return (
    <div className="h-screen w-screen pt-20 bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-6">
      <h2 className="text-3xl font-bold text-gray-800 mb-8">Schedule Pickup</h2>
      <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg space-y-4">
        <p className="text-lg">
          Order Code: <span className="font-semibold text-blue-600">{orderCode}</span>
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="date"
            name="pickupDate"
            className="w-full p-3 border rounded-lg"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            required
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition mt-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PickupDatesForm;