// export default Steps;
import React from 'react';
import PropTypes from 'prop-types';

const Steps = (props) => {
  return (
    <div className="flex flex-col items-center justify-center w-full py-16 bg-gray-50 mt-[20vh]">
      <div className="container mx-auto px-6 lg:px-16">
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Left Column (Text Content - Sticky) */}
          <div className="sticky top-[15vh] h-fit space-y-6">
            <h2 className="text-3xl font-bold text-gray-800 lg:text-4xl">
              How Does It Work ?
            </h2>
            <p className="text-gray-600">
            At TayMar J, we transform auto transport into an effortless experience where every mile is managed with expert precision. Let us handle every detail—from your initial quote to a secure delivery—while you relax and enjoy the ride.
              Your vehicle, our care. Every mile of the way. 🚛✨
            </p>
            <p>For DSP services please see our <a href="/businessinfo" class="hover:text-black text-[#1e42ee] underline">DSP SERVICE Page</a></p>
            <div>
            </div>
          </div>

          {/* Right Column (Cards with Stacking Scroll Effect) */}
          <div className="space-y-8 relative">
            {[1, 2, 3, 4].map((step, index) => {
              // Define pastel colors for the cards
              const colors = ['bg-blue-100', 'bg-yellow-100', 'bg-green-100', 'bg-blue-100'];
              const rotations = ['rotate-2', '-rotate-2', 'rotate-2', '-rotate-2'];
              return (
                <div
                  key={step}
                  className={`sticky mt- p-6 ${colors[index % colors.length]
                    } rounded-lg shadow transform ${rotations[index % rotations.length]
                    } transition-all duration-300`}
                  style={{
                    top: `${15 + index * 4}vh`, // Stack cards at the same position with slight offset
                    zIndex: index + 1, // Ensure proper stacking order
                  }}
                >
                  <h2 className="text-xl font-bold text-gray-800">
                    {props[`step${step}Title`]}
                  </h2>
                  <p
                    className="mt-4 text-gray-600"
                    dangerouslySetInnerHTML={{ __html: props[`step${step}Description`] }}
                  />
                  <label className="absolute text-4xl font-bold text-gray-300 top-2 right-4">
                    0{step}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Steps.defaultProps = {
  step1Description:
    'Go to our <a href="/quote" className="underline text-logoBlue">Get A Quote</a> page and fill out a short form. Upon submission, we’ll analyze your request and update you with a finalized rate within 24 hours—no hidden fees, no surprises. Please note, an online quote is just an estimate - The actual price could be a bit higher or lower. We will contact you with all price changes if any, BEFORE your delivery',
  step2Description:
    'Once confirmed, our team will reach out to guide you through steps for payment and a quick prep checklist to lock in your pickup appointment. We will never take payment information before you confirm your price.',
  step3Description:
    "Feel at ease knowing your access to the LTM (Live Transport Monitoring) Portal lets you stay up to date on your vehicles' movements as we transport them safely to their destination. Whether it's a short distance or a long haul, Our experienced team will take expert care of everything.",
  step4Description:
    'Upon arrival, your vehicle will be delivered promptly and in the same condition as when it was picked up-which is confirmed via pictures. Once all pictures are uploaded and documents are signed, your driver will release the vehicle over to you. Your satisfaction is our top priority. ',
  step1Title: 'Request a Quote',
  step2Title: 'Start Pickup',
  step3Title: 'Transportation Process',
  step4Title: 'Delivery and Satisfaction',
};

Steps.propTypes = {
  step1Description: <p>Go to our <a href="/quote" className="underline text-logoBlue">Get A Quote</a> page and fill out a short form. Please note, this quote is an estimate and the actual price could be slightly higher or lower.</p>,
  step2Description: PropTypes.string,
  step3Description: PropTypes.string,
  step4Description: PropTypes.string,
  step1Title: PropTypes.string,
  step2Title: PropTypes.string,
  step3Title: PropTypes.string,
  step4Title: PropTypes.string,
};

export default Steps;