import React from 'react'
import PropTypes from 'prop-types'

const CTA = (props) => {
  return (
    <div className="pt-14 mb-[-10vh] px-4 md:px-8">
      <div className="max-w-7xl bg-black hover:scale-105 transition-transform rounded transform hover:rotate-0 -rotate-2 mx-auto">
        <div className="bg-white transform rotate-2 hover:rotate-0 hover:scale-105 transition-transform rounded-lg p-8">
          <div className="bg-gray-500 transform transition-transform hover:rotate-0 rounded-lg p-8">
            <div className="flex flex-col md:flex-row gap-8 items-center justify-between">
              <div className="flex flex-col gap-4">
                <span className="text-2xl font-semibold">{props.heading1}</span>
                <p className="text-lg text-neutral-600">{props.content1}</p>
              </div>
              <div className="flex items-start justify-end">
                <a href='/ouredge'>
                  <button
                    type="button"
                    className="bg-gray-400 text-black font-semibold py-3 px-6 rounded-lg hover:bg-[#1E42EE] transition-colors"
                  >
                    {props.action1}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  heading1: 'Experience the TayMar J Transportation Difference',
  content1:
    'We believe in supporting our community by providing reliable, stress-free auto delivery solutions built on trust and efficiency. With benefits like live monitoring, transparent pricing, and full operational and brand control, we make the process quick and easy for all.',
  action1: 'Read More',
}

CTA.propTypes = {
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action1: PropTypes.string,
}

export default CTA
