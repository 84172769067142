import React from 'react';
import tmjLogo from '../images/tmjLogo.png';
import vision from '../images/vision.webp';
import mission from '../images/mission.webp';
import story from '../images/story.webp';
import Footer from '../components/footer.js';

 

const About = () => {
    return (
        // <div className="py-[5vh] pt-[15vh] flex flex-col items-center justify-center min-h-screen relative">
        //     {/* Logo in the Top-Right */}
        //     <div className="static">
        //         <img src={tmjLogo} alt="TayMar J Transportation" className="w-[50vw] md:w-[40vw] lg:w-[30vw] my-[25px]" />
        //     </div>
        //     {/* Cards Section */}
        //     <div className="w-full max-w-screen-lg flex flex-col items-center justify-center space-y-12 relative z-0">
        //         {/* Card 1 (fade from left) */}
        //         <div className="bg-white p-6 rounded-lg shadow-xl opacity-0 animate-fadeInLeft transform transition-all duration-700 ease-out z-10">
        //             <h3 className="text-xl font-semibold text-gray-800 mb-4">Our Story</h3>
        //             <p className="text-gray-700">
        //                 Born out of a true love for cars and a passion for making a positive impact, TayMar J Transportation is led by Justin Taylor. We're a young, dynamic team, dedicated to making every interaction meaningful and personal.
        //             </p>
        //         </div>

        //         {/* Card 2 (fade from right) */}
        //         <div className="bg-white p-6 rounded-lg shadow-xl opacity-0 translate-x-[100%] animate-fadeInRight transform transition-all duration-700 ease-out z-20">
        //             <h3 className="text-xl font-semibold text-gray-800 mb-4">Our Mission</h3>
        //             <p className="text-gray-700">
        //                 At the heart of what we do is empathy. We understand that behind every vehicle we transport is a person with a unique story. We treat each car like it's our own, striving to exceed your expectations.
        //             </p>
        //         </div>

        //         {/* Card 3 (fade from left) */}
        //         <div className="bg-white p-6 rounded-lg shadow-xl opacity-0 animate-fadeInLeft transform transition-all duration-700 ease-out z-30">
        //             <h3 className="text-xl font-semibold text-gray-800 mb-4">Affordability</h3>
        //             <p className="text-gray-700">
        //                 Affordability is a cornerstone of our services. We understand the financial strain of transportation, which is why we offer competitive pricing without compromising on quality.
        //             </p>
        //         </div>

        //         {/* Card 4 (fade from right) */}
        //         <div className="bg-white p-6 rounded-lg shadow-xl opacity-0 translate-x-[100%] animate-fadeInRight transform transition-all duration-700 ease-out z-40">
        //             <h3 className="text-xl font-semibold text-gray-800 mb-4">Our Vision</h3>
        //             <p className="text-gray-700">
        //                 Our vision is to grow into a nationwide brand you can trust with your vehicle. We aim to deliver seamless service and create lasting relationships with each and every customer.
        //             </p>
        //         </div>
        //     </div>
        // </div>
        <div className="py-[5vh] pt-[15vh] flex flex-col items-center justify-center min-h-screen relative bg-gradient-to-b from-blue-50 to-white">
            {/* Logo in the Bottom-Right */}
            <div className="fixed top-3 left-9 z-[101]">
                <a href='/'>
                    <img
                        src={tmjLogo}
                        alt="TayMar J Transportation"
                        className="w-[15vw] sm:w-[12vw] md:w-[10vw] lg:w-[8vw] xl:w-[6vw] 2xl:w-[5vw] hover:scale-110 transition-transform duration-300 cursor-pointer"
                    />
                </a>
            </div>

            {/* Cards Section */}
            <div className="w-full max-w-screen-lg flex flex-col items-center justify-center space-y-8 md:space-y-12 relative z-0 px-4 sm:px-6 lg:px-8">
                {/* Card 1 (Image on the right) */}
                <div className="bg-white p-6 sm:p-8 rounded-lg shadow-2xl opacity-0 animate-fadeInLeft transform transition-all duration-700 ease-out z-10 hover:shadow-3xl hover:-translate-y-2 transition-all duration-300 rotate-[-2deg] flex flex-col md:flex-row items-center gap-6 w-full">
                    <div className="flex-1">
                        <h3 className="text-xl sm:text-2xl font-bold text-blue-800 mb-4">Our Story</h3>
                        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                            Our story began in 2023, with a simple, yet powerful, idea—a single person with a deep passion for cars, dreaming of starting something of his own. Based in New Jersey, I took that leap, driven by a desire to build a company that could do things differently—offering a more affordable, reliable, and human approach to vehicle transportation. What started as a one-man operation has grown into something much bigger, fueled by a commitment to quality service and a genuine love for the game. It’s been a journey of challenges, growth, and countless lessons learned along the way, but each step has brought us closer to our vision. Today, we’re focused on building a company that’s not only about cars but about creating lasting connections and making a real impact, one delivery at a time.</p>
                    </div>
                    <div className="flex-1 w-full md:w-auto">
                        <img src={story} alt="Our Story" className="w-full h-auto rounded-lg shadow-md" />
                    </div>
                </div>

                {/* Card 2 (Image on the left) */}
                <div className="bg-white p-6 sm:p-8 rounded-lg shadow-2xl opacity-0 translate-x-[100%] animate-fadeInRight transform transition-all duration-700 ease-out z-20 hover:shadow-3xl hover:-translate-y-2 transition-all duration-300 rotate-[3deg] flex flex-col md:flex-row-reverse items-center gap-6 w-full">
                    <div className="flex-1">
                        <h3 className="text-xl sm:text-2xl font-bold text-blue-800 mb-4">Our Mission</h3>
                        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                            Our mission is driven by faith and a desire to make a meaningful impact in a world that often feels broken. The TayMar Team is committed to operating with integrity, compassion, and a strong sense of purpose in everything we do. What started as a small vision to provide affordable auto transportation is now growing into a nationwide fleet of vehicles, helping to move cars and trucks across the country. As we expand, our goal is to not only transport vehicles but also buy, sell, and lend commercial trucks, creating affordable opportunities for all to thrive. Above all, we aim to build a company grounded in strong values—offering reliable, affordable solutions that support both individuals and businesses, while making a positive impact on the world around us. Will you join us in that effort?</p>
                    </div>
                    <div className="flex-1 w-full md:w-auto">
                        <img src={mission} alt="Our Mission" className="w-full h-auto rounded-lg shadow-md" />
                    </div>
                </div>

                {/* Card 3 (Image on the left) */}
                <div className="bg-white p-6 sm:p-8 rounded-lg shadow-2xl opacity-0 translate-x-[100%] animate-fadeInLeft transform transition-all duration-700 ease-out z-40 hover:shadow-3xl hover:-translate-y-2 transition-all duration-300 rotate-[2deg] flex flex-col md:flex-row-reverse items-center gap-6 w-full">
                    <div className="flex-1 w-full md:w-auto">
                        <img src={vision} alt="Our Vision" className="w-full h-auto rounded-lg shadow-md" />
                    </div>
                    <div className="flex-1">
                        <h3 className="text-xl sm:text-2xl font-bold text-blue-800 mb-4">Our Vision</h3>
                        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                            Our vision is to grow into a nationwide leader in auto transportation, not just by expanding our fleet but by innovating for a better future. As we scale, we’re committed to integrating eco-friendly vehicles and sustainable equipment into our operations, reducing our environmental impact while maintaining the high-quality service our customers rely on. We also aim to contribute to the communities we serve by supporting local initiatives and donations, making a positive difference wherever we operate. In addition to moving vehicles, we see the future in buying, selling, and lending commercial trucks, providing affordable, accessible options for businesses of all sizes. Ultimately, we’re focused on creating a business that’s as much about the people and communities we serve as it is about the vehicles we transport.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default About;
