import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-gray-200 py-5 text-center">
      <br />
      <div>
        <p>&copy; 2025 Taymar J Transportation LLC.</p>
      </div>
      <div className="mt-2">
        <a href="/about" className="text-black hover:text-[#1e42ee]">About Us</a> |{' '}
        <a href="/services" className="text-black hover:text-[#1e42ee]">Services</a> |{' '}
        <a href="/contact" className="text-black hover:text-[#1e42ee]">Contact</a>
      </div>
      <div className="mt-2">
        <p>
          Phone: <a href="tel:6099686388" className="text-black hover:text-[#1e42ee]">+1 (609)-968-6388</a>
        </p>
        <p>
          Email: <a href="mailto:admin@tmjtransport.net" className="text-black hover:text-[#1e42ee]">admin@tmjtransport.net</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
