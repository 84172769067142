import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { FaCar, FaUser, FaQuestionCircle, FaPhone, FaMapMarkerAlt, FaDollarSign, FaTruck } from 'react-icons/fa';
import moment from "moment";
import { set } from "mongoose";


// Function to load Google Maps API script with the provided API key
const loadGoogleMapsScript = (apiKey) => {
  return new Promise((resolve, reject) => {
    if (window.google) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    // Set the attributes via setAttribute for proper async/defer loading
    script.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`);
    script.setAttribute("async", "");
    script.setAttribute("defer", "");
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const Quote = () => {
  // Map, marker, and location state variables
  const [map, setMap] = useState(null);
  const [markersArray, setMarkersArray] = useState([]);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [originCoords, setOriginCoords] = useState(null);
  const [destinationCoords, setDestinationCoords] = useState(null);
  const [distance, setDistance] = useState(null);
  const [estimateCost, setEstimateCost] = useState("");

  //State for price breakdown icon hover
  const [showBreakdown, setShowBreakdown] = useState(false);

  // Form data for customer information and vehicle details
  const [formData, setFormData] = useState({
    fullName: "",
    contact: "",
    vehicleType: "",
    numVehicles: 0,
  });

  // New state to handle steps: "quote" (default) and "pickup"
  const [step, setStep] = useState("quote");
  // State to hold pickup dates (can be expanded if needed)
  const [pickupData, setPickupData] = useState({
    pickupDate: "",
    pickupTime: "",
  });
  // For generating a unique code per quote (a1, a2, a3, etc.)
  const [quoteCount, setQuoteCount] = useState(1);

  // Load the Google Maps script on mount
  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
    loadGoogleMapsScript(apiKey)
      .then(() => {
        if (!window.google) {
          throw new Error("Google Maps JavaScript API failed to load");
        }
        // Initialize map centered in the continental U.S.
        const newMap = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 39.8283, lng: -98.5795 },
          zoom: 4,
        });
        setMap(newMap);
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error loading Google Maps API. Please try again.",
        });
      });
    return () => {
      deleteMarkers();
    };
  }, []);

  const deleteMarkers = () => {
    markersArray.forEach((marker) => marker.setMap(null));
    setMarkersArray([]);
  };

  // Initialize Google Places Autocomplete for a given input field
  const initAutocomplete = (inputId, setCoords, setAddress) => {
    const input = document.getElementById(inputId);
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.setFields(["address_component", "geometry", "formatted_address"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setCoords({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setAddress(place.formatted_address);
      }
    });
  };

  // Pricing configuration object
  const pricingConfig = {
    shortDistance: {
      maxDistance: 200,
      flatFee: 50, // Flat fee for trips under 200 miles
      perMileRate: 0.25,
    },
    mediumDistance: {
      minDistance: 201,
      maxDistance: 650,
      flatFee: 100, // Reduced flat fee for trips between 201-650 miles
      perMileRate: 0.75, // Reduced per-mile rate
    },
    longDistance: {
      minDistance: 651,
      maxDistance: 3000,
      flatFee: 300, // Reduced flat fee for trips above 650 miles (up to 3000)
      perMileRate: 1.10, // Reduced per-mile rate
    },
    // Default fuel and surcharge data
    fuel: {
      fuelCostPerGal: 4.00, // $4/gallon
      mpg: 10, // 10 miles/gallon
      fuelSurchargeFactor: 0.60, // Reduced to 60% of fuel cost
    },
    // Dynamic profit margin based on distance
    profitMargin: (miles) => {
      if (miles <= 200) return 1.02; // 2% profit for short distances
      if (miles <= 650) return 1.08; // 8% profit for medium distances
      return 1.15; // 15% profit for long distances
    },
  };

  // Function to calculate cost
    //State for updating price breakdown on hover
    const [flatFeeBreakdown, setFlatFeeBrekadown] = useState(0)
    const [taxesBreakdown, setTaxesBreakdown] = useState(0)
    const [totalCostBreakdown, setTotalCostBreakdown] = useState(0)
    const [fuelChargeBreakdown, setFuelChargeBreakdown] = useState(0)
    const [perMileCostBreakdown, setPerMileCostBreakdown] = useState(0)
  const calculateCost = (miles, numVehicles, origin = "", destination = "") => {
    // Check if the trip is in New Jersey
    const isNJ = (address) => (address || "").includes(", NJ") || (address || "").includes(", New Jersey");
    const njTrip = isNJ(origin) || isNJ(destination);

    // Calculate fuel surcharge (60% of fuel cost)
    const fuelSurcharge = (miles / pricingConfig.fuel.mpg * pricingConfig.fuel.fuelCostPerGal) * pricingConfig.fuel.fuelSurchargeFactor;

    let baseRate = 0;
    let flatFee = 0;
    let perMileRate = 0;

    // Determine pricing tier based on distance
    if (miles <= pricingConfig.shortDistance.maxDistance) {
      flatFee = pricingConfig.shortDistance.flatFee;
      perMileRate = pricingConfig.shortDistance.perMileRate;
    } else if (miles >= pricingConfig.mediumDistance.minDistance && miles <= pricingConfig.mediumDistance.maxDistance) {
      flatFee = pricingConfig.mediumDistance.flatFee;
      perMileRate = pricingConfig.mediumDistance.perMileRate;
    } else if (miles >= pricingConfig.longDistance.minDistance && miles <= pricingConfig.longDistance.maxDistance) {
      flatFee = pricingConfig.longDistance.flatFee;
      perMileRate = pricingConfig.longDistance.perMileRate;
    } else {
      // Handling very long distance trips, above 3000 miles
      flatFee = 500; // Reduced flat fee
      perMileRate = 1.25; // Reduced per-mile rate
    }

    // Adjust base rate for NJ trips
    if (njTrip) {
      flatFee += 20; // Add a small surcharge for New Jersey trips
    }

    // Base rate includes flat fee and per mile cost
    const perMileCost = miles * perMileRate;
    baseRate = flatFee + perMileCost;

    // Calculate final cost with profit and fuel surcharge
    const profitMargin = pricingConfig.profitMargin(miles); // Dynamic profit margin
    const costWithProfit = (baseRate + fuelSurcharge) * profitMargin;
    const finalCost = costWithProfit * numVehicles;

    // Dynamic minimum charge based on number of vehicles
    const dynamicMinimum = Math.max(100, 100 + (numVehicles - 1) * 50);
    const totalCost = Math.max(finalCost, dynamicMinimum);

    // Log the cost breakdown (could be useful for debugging or transparency)
    console.log(`Cost Breakdown:
    Flat Fee: $${flatFee}
    Per Mile Cost: $${(miles * perMileRate).toFixed(2)}
    Fuel Surcharge: $${fuelSurcharge.toFixed(2)}
    Profit Margin: ${((profitMargin - 1) * 100).toFixed(2)}%
    Total Cost: $${totalCost.toFixed(2)}
  `);
    setFlatFeeBrekadown(`${flatFee}`);
    setFuelChargeBreakdown(`${fuelSurcharge.toFixed(2)}`);
    setTaxesBreakdown(`${((profitMargin - 1) * 100).toFixed(2)}`)
    setTotalCostBreakdown(`${totalCost.toFixed(2)}`);
    setPerMileCostBreakdown(`${(miles*perMileRate).toFixed(2)}`)
    


    // Set the estimated cost
    setEstimateCost(totalCost.toFixed(2));


    return totalCost;
  };


  // Add useEffect to recalculate price whenever the necessary data changes
  // const calculateMilesBetweenLocations = (origin, destination) => {
  //   console.log(origin, destination);
  // };
  // useEffect(() => {
  //   if (origin && destination && formData.numVehicles > 0) {
  //     const miles = calculateMilesBetweenLocations(origin, destination); // This function should calculate distance in miles
  //     const cost = calculateCost(miles, formData.numVehicles, origin, destination);
  //     setPickupData((prevData) => ({
  //       ...prevData,
  //       estimatePrice: cost.toFixed(2), // Set the calculated price in the state
  //     }));
  //   }
  // }, [origin, destination, formData.numVehicles]); // Dependencies to recalculate price

  // Validate the form: fullName, contact must be valid
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (!formData.fullName || !formData.vehicleType) {
      Swal.fire("Error", "Please fill in all required fields", "error");
      return false;
    }

    if (!emailRegex.test(formData.contact) && !phoneRegex.test(formData.contact)) {
      Swal.fire("Error", "Please enter a valid email or phone number", "error");
      return false;
    }

    if (!formData.numVehicles || isNaN(formData.numVehicles) || formData.numVehicles < 1) {
      Swal.fire("Error", "Please specify a valid number of vehicles (must be at least 1)", "error");
      return false;
    }

    return true;
  };

  // Calculate distance and cost
  const calculateDistance = async () => {
    if (!validateForm()) return;
    if (!originCoords || !destinationCoords) {
      Swal.fire("Warning", "Please select both origin and destination", "warning");
      return;
    }
    try {
      const service = new window.google.maps.DistanceMatrixService();
      const response = await new Promise((resolve, reject) => {
        service.getDistanceMatrix(
          {
            origins: [originCoords],
            destinations: [destinationCoords],
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          },
          (response, status) => (status === "OK" ? resolve(response) : reject(status))
        );
      });
      const element = response.rows[0].elements[0];
      if (element.status !== "OK") throw new Error("Route not found");

      const miles = parseFloat(element.distance.text.replace(/[^0-9.]/g, ""));
      const numVehicles = parseInt(formData.numVehicles, 10) || 1; // Default to 1 if empty
      const totalCost = calculateCost(miles, numVehicles, origin, destination);

      setDistance(`Distance: ${element.distance.text} - Estimated Price: $${totalCost.toFixed(2)}`);
      addMarkers(originCoords, destinationCoords);
    } catch (error) {
      Swal.fire("Error", "Could not calculate distance. Please check addresses", "error");
      console.error("Distance calculation error:", error);
    }
  };

  // Use standard Marker to avoid AdvancedMarkerElement deprecation warnings
  const addMarkers = (originLocation, destinationLocation) => {
    deleteMarkers();
    const originMarker = new window.google.maps.Marker({
      map: map,
      position: originLocation,
      label: "O",
    });
    const destinationMarker = new window.google.maps.Marker({
      map: map,
      position: destinationLocation,
      label: "D",
    });
    setMarkersArray([originMarker, destinationMarker]);
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(originLocation);
    bounds.extend(destinationLocation);
    map.fitBounds(bounds);
  };

  useEffect(() => {
    // Only initialize the autocomplete if we are in the quote step.
    if (step === "quote") {
      initAutocomplete("origin-input", setOriginCoords, setOrigin);
      initAutocomplete("destination-input", setDestinationCoords, setDestination);
    }
  }, [step]);
  //Handle for contact and quote info
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handler for the Yes/No buttons once a quote is calculated
  const handleProceedChoice = (choice) => {
    if (choice === "yes") {
      setStep("pickup");
    } else {
      Swal.fire("Thank you!", "Thank you for using our quote service.", "info");
      // Optionally, you might want to reset the form or redirect.
    }
  };

  // Build the time options array: every half-hour (AM/PM)
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      const period = hour < 12 ? "AM" : "PM";
      let hour12 = hour % 12;
      if (hour12 === 0) hour12 = 12;
      const minuteStr = minute === 0 ? "00" : "30";
      const label = `${hour12}:${minuteStr} ${period}`;
      timeOptions.push({ value: label, label });
    }
  }
  const currentTime = moment().format("MM-DD-YYYY HH:mm") ;

  // Submit the pickup form along with the quote data to your custom API
  const handlePickupSubmit = async (e) => {
    e.preventDefault();
  
    // Create a unique code e.g., "a1", "a2", etc.
    const uniqueCode = `a${quoteCount}`;
    // Prepare the payload with all collected data
    const payload = {
      uniqueCode,
      customerInfo: formData,
      origin,
      destination,
      distanceEstimate: distance,
      pickupData,
      estimate: estimateCost,
      timeSubmitted: currentTime
    };
  
    try {
      // Replace the URL below with your actual API endpoint
      const response = await fetch("https://pickup-api-production-c69e.up.railway.app/quotes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json(); // Parse the response body
      if (!response.ok) {
        throw new Error("Failed to send data to the API");
      }
      Swal.fire({
        title: "Success",
        text: "Your pickup dates have been submitted!",
        icon: "success",
        confirmButtonText: "OK"
      }).then(() => {
        // Redirect to the "Thank You" page after closing the popup
        window.location.href = "/thanks";  // Change this URL if necessary
      });
  
      setQuoteCount(quoteCount + 1);
      // Optionally, reset the forms or redirect to a confirmation page.
    } catch (error) {
      console.error("Error submitting pickup data:", error);
      Swal.fire("Error", "There was a problem submitting your pickup dates.", "error");
    }
  };
  


  // Render the quote page (with the map, quote form, and yes/no buttons) OR the pickup form based on the current step.
  return (
    <div className="h-screen w-screen pt-20 bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-6">
      {step === "quote" && (
        <>
          <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg space-y-4">
            <h2 className="text-3xl font-bold text-gray-800 mb-8">Instant Quote</h2>
            {/* Customer Information Inputs */}
            <span className="flex flex-row space-x-2 justify-around">
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                className="w-[50%] p-3 border rounded-lg"
                onChange={handleChange}
              />
              <input
                type="text"
                name="contact"
                placeholder="Email or Phone"
                className="w-[50%] p-3 border rounded-lg"
                onChange={handleChange}
              />
            </span>
            {/* Address Inputs for Auto-suggestions */}
            <span className="flex flex-row space-x-2 justify-around">
              <input
                type="text"
                id="origin-input"
                placeholder="Enter origin"
                className="w-[50%] p-3 border rounded-lg"
              />
              <input
                type="text"
                id="destination-input"
                placeholder="Enter destination"
                className="w-[50%] p-3 border rounded-lg"
              />
            </span>
            {/* Vehicle Information Inputs */}
            <span className="flex flex-row space-x-2 justify-around">
              <select
                name="vehicleType"
                className="w-[50%] p-3 border rounded-lg"
                onChange={handleChange}
              >
                <option value="">Select Vehicle Type</option>
                <option value="coupe">Coupe</option>
                <option value="sedan">Sedan</option>
                <option value="suv">SUV</option>
                <option value="pickup">Pickup</option>
                <option value="van">Van</option>
                <option value="motorcycle">Motorcycle</option>
                <option value="cart">Cart</option>
              </select>
              <input
                type="number"
                name="numVehicles"
                placeholder="Number of Vehicles"
                className="w-[50%] p-3 border rounded-lg"
                min="1"
                onChange={handleChange}
              />
            </span>
            <button
              onClick={calculateDistance}
              className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition"
            >
              Calculate Distance
            </button>
            {distance && (
              <div className="text-xl font-semibold text-center mt-4">
                <span className="text-blue-600">{distance}</span>
              </div>
            )}
            {/* Show Yes/No buttons if a quote has been calculated */}
            {estimateCost > 0 && (
              <div className="flex flex-row justify-center items-center space-x-4 mt-4 relative">
                {/* Information Icon */}
                <div className="group relative">
                  <button
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                    onMouseEnter={() => setShowBreakdown(true)}
                    onMouseLeave={() => setShowBreakdown(false)}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowBreakdown(!showBreakdown);
                    }}
                    aria-label="Price breakdown"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                  {/* Breakdown Tooltip */}
                  {showBreakdown && (
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64 bg-white p-4 rounded-lg shadow-lg border border-gray-200 text-sm z-50">
                      <h4 className="font-semibold mb-2">Price Breakdown:</h4>
                      <div className="text-gray-600 space-y-1">
                        <p><strong>Flat Fee:</strong> {flatFeeBreakdown}</p>
                        <p><strong>Per Mile Cost:</strong> {perMileCostBreakdown}</p>
                        <p><strong>Fuel Surcharge:</strong> {fuelChargeBreakdown}</p>
                        <p><strong>Taxes:</strong> {taxesBreakdown}</p>
                        <p><strong>Total Cost:</strong> {totalCostBreakdown}</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* Yes/No Buttons */}
                <button
                  onClick={() => handleProceedChoice("yes")}
                  className="bg-green-600 text-white p-2 rounded hover:bg-green-700 transition"
                >
                  Yes
                </button>
                <button
                  onClick={() => handleProceedChoice("no")}
                  className="bg-red-600 text-white p-2 rounded hover:bg-red-700 transition"
                >
                  No
                </button>
              </div>
            )}
          </div>
          <div id="map" className="w-full max-w-2xl h-96 mt-8 rounded-lg shadow-lg"></div>
        </>
      )}

      {step === "pickup" && (
        <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl text-center pt-[25px] font-bold text-gray-800 mb-8">What Time Works For You?</h2>
          <form onSubmit={handlePickupSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Pickup Date</label>
              <DatePicker
                selected={pickupData.pickupDate ? new Date(pickupData.pickupDate) : null}
                onChange={(date) =>
                  setPickupData((prev) => ({
                    ...prev,
                    pickupDate: date ? date.toISOString().split("T")[0] : "",
                  }))
                }
                className="w-full p-3 border rounded-lg"
                dateFormat="MM-dd-yyyy"
                placeholderText="Select a date"
                required
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}  // Adjusts the number of years shown in the dropdown
                calendarClassName="shadow-lg rounded-lg" // Adds a nice shadow and rounded corners
                minDate={new Date()}  // Prevents selection of any date in the past
              />
            </div>
            <div>
              <label className="block text-gray-700">Pickup Time</label>
              <Select
                options={timeOptions}
                value={timeOptions.find(
                  (option) => option.value === pickupData.pickupTime
                )}
                onChange={(time) =>
                  setPickupData((prev) => ({
                    ...prev,
                    pickupTime: time.value, // Update pickupTime correctly
                  }))
                }
                className="w-full"
                placeholder="Select a time"
                isSearchable
              />

            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition"
            >
              Confirm Pickup Info
            </button>
          </form>
          <div className="mt-6">
            <h2 className="text-xl font-semibold">Confirm Your Information</h2>
            <p className="relative w-full pr-10">
              <strong>Car Type:</strong> {formData.vehicleType}
              <FaCar className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Full Name:</strong> {formData.fullName}
              <FaUser className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Phone/Email:</strong> {formData.contact}
              <FaPhone className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Pickup Location:</strong> {origin}
              <FaMapMarkerAlt className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Drop-off Location:</strong> {destination}
              <FaMapMarkerAlt className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Estimate Price:</strong> {estimateCost}
              <FaDollarSign className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
            <p className="relative w-full pr-10">
              <strong>Amount of Vehicles:</strong> {formData.numVehicles}
              <FaTruck className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quote;
