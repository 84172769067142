import React from 'react';
import { FaHome } from 'react-icons/fa'; // Importing the home icon

const DetailThankYouPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-300 to-blue-500 flex items-center justify-center">
            <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-lg mx-auto">
                <h1 className="text-4xl font-bold text-blue-600 mb-4">Thank You!</h1>
                <p className="text-lg text-gray-700 mb-6">
                    Thank you for choosing us! 🙏

                    We can’t thank you enough for your service – your vehicle is in trusted hands, and we promise to treat it like our own. 🛠️💙

                    Please make sure to remove any personal items you dont want thrown away before your appointment. You can relax for now - We’ll keep things smooth and simple – just like your ride. 🚗✨


                </p>
                <a href="/" className="inline-flex items-center text-white bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-lg shadow-md transition duration-300">
                    <FaHome className="mr-2" />
                    Go Back Home
                </a>
            </div>
        </div>
    );
};

export default DetailThankYouPage;
