import React from 'react';
import retrocars from '../images/retrocars.jpg';
import getstartedimg from '../images/getstartedimg.jpg';
import scalability from '../images/scalability.png';
import Footer from '../components/footer.js';


const Businessinfo = () => {
    return (
        <div className="h-screen overflow-y-scroll snap-y snap-mandatory">
            {/* Section 1 - Content Left */}
            <section className="h-screen snap-start bg-black text-white flex flex-col md:flex-row items-center justify-center px-4 md:px-8 lg:px-12">
                {/* Horizontal line for mobile */}
                <div className="block md:hidden my-[20px] w-full h-1 bg-blue-500"></div>
                <div className="flex-1 w-full md2:max-w-2xl space-y-6 md2:pr-12">
                    {/* Vertical line for desktop */}
                    <div className="hidden md2:block w-1 h-56 bg-blue-500"></div>
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Empower Your Core Values</h1>
                    <p className="text-sm md:text-lg lg:text-xl text-gray-400">
                        We manage all operations and take on maintenance costs, allowing you to focus on growth.
                        Enjoy efficient, data-driven auto delivery solutions that keeps your brand at the forefront.
                    </p>
                    <ul className="list-disc pb-6 pl-5 text-sm md:text-base lg:text-lg text-gray-400">
                        <li>Offload fleet overhead</li>
                        <li>Comprehensive driver oversight</li>
                        <li>Effective communication systems</li>
                        <li>Cost-effective operations management</li>
                    </ul>
                </div>
                <div className="flex-1 w-full md2:max-w-xl">
                    <img
                        src={retrocars}
                        alt="Placeholder"
                        className="rounded-lg shadow-lg w-full h-auto max-h-[35vh] md2:max-h-[45vh] lg:max-h-full object-contain"
                    />
                </div>
            </section>



            {/* Section 2 - Content Right */}
            <section className="h-screen snap-start bg-white text-gray-700 flex flex-col md:flex-row-reverse items-center justify-center px-4 md:px-8 lg:px-12">
                {/* Horizontal line for mobile */}
                <div className="block md:hidden my-[20px] w-full h-1 bg-blue-500"></div>
                <div className="flex-1 w-full md2:max-w-2xl space-y-6 md2:pl-12">
                    {/* Vertical line for desktop */}
                    <div className="hidden md2:block w-1 h-56 bg-blue-500"></div>
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Flexible, Scalable Pricing</h1>
                    <p className="text-sm md:text-lg lg:text-xl text-gray-900">
                        Only pay for what you use. Our flexible model charges $0.25 - $1.50 per mile per vehicle, based on demand. If you exceed your estimated usage, the extra mileage is simply invoiced—no hidden fees.
                    </p>
                    <ul className="list-disc pb-6 pl-5 text-sm md:text-base lg:text-lg text-gray-900">
                        <li>Custom pricing for monthly & yearly contracts</li>
                        <li>Simple invoicing for additional mileage</li>
                        <li>Sell vehicles online with near-zero costs</li>
                    </ul>
                </div>
                <div className="flex-1 w-full md2:max-w-xl">
                    <img
                        src={scalability}
                        alt="Placeholder"
                        className="rounded-lg w-full h-auto max-h-[35vh] md2:max-h-[45vh] lg:max-h-full object-contain"
                    />
                </div>
            </section>


            {/* Section 3 - Content Left */}
            <section className="h-screen snap-start bg-gradient-to-r from-blue-600 to-gray-800 text-white flex flex-col md:flex-row items-center justify-center px-4 md:px-8 lg:px-12">
                {/* Horizontal line for mobile */}
                <div className="block md:hidden my-[20px] w-full h-1 bg-blue-500"></div>
                <div className="flex-1 w-full md2:max-w-2xl space-y-6 md2:pr-12">
                    {/* Vertical line for desktop */}
                    <div className="hidden md2:block w-1 h-56 bg-blue-500"></div>
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">Get Started Today</h1>
                    <p className="text-sm md:text-lg lg:text-xl text-gray-200">
                        Connecting with us is simple. Reach out via phone or contact us directly, and our DSP team will guide you through the setup process.
                    </p>
                    <ul className="list-disc pb-6 pl-5 text-sm md:text-base lg:text-lg text-gray-200">
                        <li>Quick and easy onboarding</li>
                        <li>Personalized solutions for your business</li>
                        <li>Tailored solutions in 72 hours or less </li>
                        <li>Call us at <a href="tel:+6099686388" className="hover:text-[#1E42EE] text-black">+1 (609) 968-6388</a>&nbsp;or&nbsp;shoot us an&nbsp;<a href='/contact' className="text-black hover:text-[#1E42EE]">Email</a></li>
                    </ul>
                </div>
                <div className="flex-1 w-full md2:max-w-xl">
                    <img
                        src={getstartedimg}
                        alt="Placeholder"
                        className="rounded-lg shadow-lg w-full h-auto max-h-[35vh] md2:max-h-[45vh] lg:max-h-full object-contain"
                    />
                </div>
            </section>
        <Footer/>
        </div>
    );
};

export default Businessinfo;
