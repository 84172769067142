import React from 'react';
import Footer from '../components/footer.js';

const Services = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8" style={{ paddingTop: '15vh' }}>
      <div className="max-w-7xl mx-auto">

        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-6xl font-serif">
            Our Premium Services
          </h1>
          <p className="mt-4 text-2xl text-gray-600 font-light">
            Reliable, Efficient, and Secure Auto Transportation Solutions for Your Business or Personal Needs
          </p>
        </div>

         {/* New Call to Action Card */}
         <div className="tracking-wider bg-white rounded-lg shadow-xl p-6 mb-8 text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Mobile Detailing and Carwash
          </h2>
          <p className="text-gray-600 mb-4">
            Click below to take advantage of the best detailing prices on the market
          </p>
          <a
            href="/autospa" // Adjust the link to match your routing
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-300"
          >
            Learn More
          </a>
        </div>

        {/* Services Grid: Diagonal Card Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
          {/* Service Card 1: Vehicle Relocation */}
          <div className="bg-white rounded-lg shadow-xl overflow-hidden transform hover:scale-105 transition-transform duration-300 mb-8">
            <div className="p-6 relative z-10">
              <div className="flex items-center justify-center w-12 h-12 bg-blue-500 rounded-full text-white mb-4">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                  ></path>
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Vehicle Relocation
              </h2>
              <p className="text-gray-600 leading-relaxed">
                Our team will pickup and deliver up to 9 vehicles at a time, with no limit on distance. Utilizing state-of-the-art equipment, we guarantee swift and safe transport of your vehicles. </p>
              <ul className="text-gray-600 mt-6 list-disc pl-6">
                <li>For businesses and individuals</li>
                <li>Across any distance – local, regional, national</li>
                <li>Efficient and secure transport of vehicles</li>
                <li>On-time, transparent delivery guaranteed</li>
                <li>Competitive pricing</li>
              </ul>
            </div>
          </div>

          {/* Service Card 2: Vehicle Delivery/Recovery */}
          <div className="bg-white rounded-lg shadow-xl overflow-hidden transform hover:scale-105 transition-transform duration-300 mb-8">
            <div className="p-6 relative z-10">
              <div className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-full text-white mb-4">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Vehicle Delivery & Recovery - DSP Service
              </h2>
              <p className="text-gray-600 leading-relaxed">
                Our Vehicle Delivery & Recovery service allows businesses to outsource their vehicle delivery and pickup needs. By partnering with us, businesses can significantly reduce costs, streamline operations, and minimize liability. We handle the entire delivery process on your behalf, freeing you from the burden of maintaining a fleet, managing drivers, or dealing with administrative hassles</p>
              <ul className="text-gray-600 mt-6 list-disc pl-6">
                <li>Cut Costs on Truck Maintenance, Salaries, & Overhead</li>
                <li>Streamline Logistics</li>
                <li>Relieve administrative workloads</li>
                <li>Professional Team of Certified & Vetted Drivers </li>
                <li>We insure, train, and manage drivers and equipment</li>
                <li>
                  <a href='businessinfo' className='text-black hover:text-[#1E42EE]'>
                    Click Here to&nbsp;Get Started
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="mt-12 pb-[25px] text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-4 font-serif">
            Ready to Transport with Us?
          </h2>
          <p className="text-xl text-gray-600 mb-8 font-light">
            Contact us today to get started with our reliable vehicle relocation and DSP services.
          </p>
          <a
            href="/quote"
            className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-300"
          >
            Get a Quote
          </a>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Services;
