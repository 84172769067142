import React from 'react';
import '../styles/ouredge.css'; // For custom animations
import Footer from '../components/footer.js';


const OurEdge = () => {
  const features = [
    {
      icon: '💲',
      title: 'Transparent Pricing',
      content: 'Guaranteed upfront pricing with written confirmation before any work begins. Changes require your approval first.',
      accent: 'bg-blue-100'
    },
    {
      icon: '🚛',
      title: 'Live Transport Tracking',
      content: 'Real-time vehicle tracking on all shipments via our ',
      link: {
        text: 'online portal',
        url: '#'
      },
      accent: 'bg-green-100'
    },
    {
      icon: '👥',
      title: 'Dedicated Team',
      content: 'Direct access to your assigned team via phone, text, or portal messaging - no automated systems.',
      accent: 'bg-yellow-100'
    },
    {
      icon: '⚡',
      title: '24/7 Rapid Response',
      content: 'Average 15-minute response time with emergency support available round the clock.',
      accent: 'bg-pink-100'
    },
    {
      icon: '🌱',
      title: 'Local & Sustainable',
      content: 'Our core values include serving the community in a positive manner wherever we can.',
      accent: 'bg-purple-100'
    }
  ];

  return (
    <section className="max-w-7xl bg-gradient-to-r from-blue-50 to-indigo-50 pt-[15vh] mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold text-center mb-12 animate-fade-in">
        Why We Stand Out
      </h2>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div 
            key={index}
            className={`${feature.accent} p-6 rounded-2xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl animate-card-enter`}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="text-4xl mb-4">{feature.icon}</div>
            <h3 className="text-2xl font-semibold mb-3">{feature.title}</h3>
            <p className="text-gray-600 mb-4">
              {feature.content}
              {feature.link && (
                <a 
                  href={feature.link.url} 
                  className="text-blue-600 hover:underline ml-1"
                >
                  {feature.link.text}
                </a>
              )}
            </p>
          </div>
        ))}
      </div>
      <Footer />
    </section>
  );
};

export default OurEdge;