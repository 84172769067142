import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home.js';
import Contact from './pages/contact.js';
import About from './pages/about.js';
import Services from './pages/services.js';
import Quote from './pages/quote.js';
import DataTrackerApp from './tools/datatracker.js';
import OurEdge from './pages/ouredge.js';
import ThankYouPage from './pages/thanks.js';
import PickupDatesForm from './pages/pickupdate.js';
import Businessinfo from './pages/businessinfo.js';
import TayMarDetailing from './pages/taymarDetailing.js';
import DetailQuoteForm from './pages/detailingQuote.js';
import DetailThankYouPage from './pages/thanksDetailing.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/detailquote" element={<DetailQuoteForm />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/detailthanks" element={<DetailThankYouPage />}></Route>
        <Route path="/autospa" element={<TayMarDetailing />}></Route>
        <Route path="/data" element={<DataTrackerApp />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/thanks" element={<ThankYouPage />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/quote" element={<Quote />}></Route>
        <Route path="/businessinfo" element={<Businessinfo />}></Route>
        <Route path="/ouredge" element={<OurEdge />}></Route>
        <Route path="/pickupdate" element={<PickupDatesForm />}></Route>
        
      </Routes>
    </Router>
  );
}

export default App;
