import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import emailjs from '@emailjs/browser'; // make sure this is at the top of your file




const DetailQuoteForm = () => {
 
  const [step, setStep] = useState(1);
  const [services, setServices] = useState({
    carwash: false,
    basicDetail: false,
    interiorProtectant: false,
    tireShine: false,
    seatCleaning: false,
    basic: false,
    premium: false,
    elite: false,
    petHair: false,
    hotMess: false,
    rimAndWindow: false,
    airFreshener: false,
    graphene: false,
    waxAndPolish: false,
  });
  const [contact, setContact] = useState({ name: '', email: '', phone: '', streetAddress: '', zipCode: '' });
  const [selectedDate, setSelectedDate] = useState(new Date());




  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContact((prev) => ({ ...prev, [name]: value }));
  };
  const handleServiceChange = (e) => {
    const { name, checked } = e.target;

    const coreServices = ['carwash', 'basic', 'premium', 'elite'];

    setServices((prev) => {
      if (coreServices.includes(name)) {
        if (!checked) {
          return { ...prev, [name]: false };
        }

        const updatedCore = coreServices.reduce((acc, service) => {
          acc[service] = false;
          return acc;
        }, {});

        return { ...prev, ...updatedCore, [name]: true };
      }

      // For add-ons or other toggles
      return { ...prev, [name]: checked };
    });
  };



  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const quoteData = {
      services,         // The service selected by the user (object)
      contact,          // The user's contact information (object)
      scheduledDate: selectedDate,  // The time the user wants the service (Date object)
    };
  
    // Create a string of selected services
    const selectedServices = Object.entries(services)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .join(', ');
  
    // Build the template parameters so EmailJS receives only primitives
    const templateParams = {
      services: selectedServices, // e.g., "carwash, waxAndPolish"
      contact_name: contact.name,
      contact_email: contact.email,
      contact_phone: contact.phone,
      contact_streetAddress: contact.streetAddress,
      contact_zipCode: contact.zipCode,
      scheduledDate: selectedDate.toString(), // Format as needed
    };
  
    // log template vairables
    console.log('EmailJS Template Params:', templateParams);

    // Send an email alert using EmailJS
    emailjs
      .send(
        'AutoSpaRequest',
        'template_zm6ukw6',
        templateParams,
        '6BnWXtAJp9KujfoUQ'
      )
      .then(() => {
        // Email sent successfully — now send data to the backend
        fetch('https://pickup-api.onrender.com/api/schedule', { // Correct route
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(quoteData),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.appointment) {
              Swal.fire({
                icon: 'success',
                title: 'Submission Successful!',
                text: 'Your quote request has been submitted!',
              });
              // Reset the form; note that we reset services to their original structure
              setServices({
                carwash: false,
                basicDetail: false,
                interiorProtectant: false,
                tireShine: false,
                seatCleaning: false,
                basic: false,
                premium: false,
                elite: false,
                petHair: false,
                hotMess: false,
                rimAndWindow: false,
                airFreshener: false,
                graphene: false,
                waxAndPolish: false,
              });
              setContact({ name: '', email: '', phone: '', streetAddress: '', zipCode: '' });
              setSelectedDate(new Date());
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error submitting your quote. Please try again.',
              });
            }
          })
          .then(() => {
            window.location.href = '/detailthanks'; // Redirect to the first screen
          })
          .catch((err) => {
            console.error('Submission error:', err);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There was an error submitting your quote. Please try again.',
            });
          });
      })
      .catch((emailErr) => {
        console.error('EmailJS error:', emailErr);
        Swal.fire({
          icon: 'error',
          title: 'Email Error',
          text: 'Failed to send email notification. Please try again.',
        });
      });
  };
  


  // Define this function in your component (outside the return block)
  const handleStep2Next = (e) => {
    const form = e.target.closest("form");
    if (form.checkValidity()) {
      nextStep();
    } else {
      // This will trigger the browser's built‑in validation messages
      form.reportValidity();
    }
  };


  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-10 px-4">
      <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-6">Book your Appointment</h1>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Select Your Services</h2>
              <p className="mb-4">Choose one or more service tiers:</p>
              <div className="space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="carwash"
                    checked={services.carwash}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>CarWash</strong> <span className="line-through text-gray-500">$40</span> $30 Promo Offer – Full exterior wash, including windows, wheels and tires.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="basicDetail"
                    checked={services.basicDetail}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Interior Cleaning & Detail</strong> <span className="line-through text-gray-500">$40</span> $30 Promo Offer – Interior vacuuming, dusting, and surface cleaning.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="basic"
                    checked={services.basic}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Value Package</strong> (Regular: $75, Promo: $60) – Full Service car wash + Interior vacuuming, dusting, and interior surface cleaning.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="premium"
                    checked={services.premium}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Premium Package</strong> (Regular: $95, Promo: $75) – Includes Basic + Interior condition/protect, RainX, & Rim Protector for a lasting clean.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="elite"
                    checked={services.elite}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Luxury Package</strong> (Regular: $125, Promo: $105) – Includes Premium + Graphene Glass Coat and Wax & Polish .
                  </span>
                </label>
              </div>

              <h3 className="text-xl font-semibold mt-6 mb-2">Optional Add‑Ons</h3>
              <div className="space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="petHair"
                    checked={services.petHair}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Pet Hair Removal - <strong>$10</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="rimAndWindow"
                    checked={services.rimAndWindow}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    RainX & Rim Protector (Provides glossy finish & Reduces grime buildup) - <strong>$20</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="interiorProtectant"
                    checked={services.interiorProtectant}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Interior Condition & Protect - <strong>$5</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="tireShine"
                    checked={services.tireShine}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Tire Shine - <strong>$5</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="airFreshener"
                    checked={services.airFreshener}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Air Freshener - <strong>$5</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="seatCleaning"
                    checked={services.seatCleaning}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Seat Cleaning - <strong>$15</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="graphene"
                    checked={services.graphene}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Graphene Glass Coat (Premium, long-lasting Water Repellent) - <strong>$20</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="waxAndPolish"
                    checked={services.waxAndPolish}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Wax and Polish (Removes light scratches and swirl marks, leaving behind a deep, glossy finish.) - <strong>$20</strong>
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="hotMess"
                    checked={services.hotMess}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Hot Mess Cleanup (Varies based on severity) - <strong>$45</strong>
                  </span>
                </label>
              </div>

              <div className="mt-8 flex justify-between">
                <a href='/autospa' className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded">Back</a>
                <button
                  type="button"
                  onClick={() => {
                    const coreServiceSelected =
                      services.carwash || services.basicDetail || services.basic || services.premium || services.elite;
                    if (!coreServiceSelected) {
                      alert("Please select at least one service.");
                      return;
                    }
                    nextStep();
                  }}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-lg mb-1">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={handleContactChange}
                    required
                    pattern=".*[A-Za-z]+.*"
                    title="Name must contain at least one letter."
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-lg mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={contact.email}
                    onChange={handleContactChange}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    title="Please enter a valid email address (e.g., user@mail.com)."
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Phone</label>
                  <InputMask
                    mask="(999) - 999 - 9999"
                    maskChar=""
                    type="tel"
                    name="phone"
                    value={contact.phone}
                    onChange={handleContactChange}
                    required
                    title="Phone number must be in the format (777) - 777 - 7777."
                    placeholder="(777) - 777 - 7777"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Street Address</label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={contact.streetAddress}
                    onChange={handleContactChange}
                    required
                    placeholder="Enter Street Address"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Zip Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={contact.zipCode}
                    onChange={handleContactChange}
                    required
                    pattern="\d{5}(-\d{4})?"
                    title="Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                    placeholder="Enter Zip Code"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={handleStep2Next}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Schedule Your Service</h2>
              <p className="mb-4 text-lg">Select your preferred date and time:</p>
              <div className="mb-6">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  showTimeSelect
                  dateFormat="Pp"
                  minDate={new Date()}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={nextStep}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Review
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Review &amp; Submit</h2>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Selected Services:</h3>
                <ul className="list-disc list-inside">
                  {services.basic && <li>Basic Detail</li>}
                  {services.premium && <li>Premium Detail</li>}
                  {services.elite && <li>Elite Detail</li>}
                  {services.petHair && <li>Pet Hair Removal Add‑On</li>}
                  {services.hotMess && <li>Hot Mess Cleanup Add‑On</li>}
                  {services.carwash && <li>Car Wash</li>}
                  {services.basicDetail && <li>Basic Detail</li>}
                  {services.interiorProtectant && <li>Interior Protectant</li>}
                  {services.tireShine && <li>Tire Shine</li>}
                  {services.seatCleaning && <li>Seat Cleaning</li>}
                  {services.rimAndWindow && <li>Rim & Window Protector</li>}
                  {services.airFreshener && <li>Air Freshener</li>}
                  {services.graphene && <li>Graphene Glass Coat</li>}
                  {services.waxAndPolish && <li>Wax & Polish</li>}
                </ul>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Contact Information:</h3>
                <p className="text-lg">{contact.name} | {contact.email} | {contact.phone}</p>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Location:</h3>
                <p className="text-lg">{contact.streetAddress} | {contact.zipCode}</p>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Scheduled Date/Time:</h3>
                <p className="text-lg">{selectedDate.toString()}</p>
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Submit Quote Request
                </button>
              </div>
            </div>
          )}

        </form>
      </div>
    </div>
  );

};

export default DetailQuoteForm;
