import '../styles/home.css';
import { useEffect, useRef, useState } from 'react';
// import pngegg from '../images/pngegg.png';
import lightStrip from '../images/lightStrip.jpg';
import Features from '../components/Features.js';
// import PricingSection from '../components/PricingHP.js';
import CTA from '../components/cta.js';
import Steps from '../components/steps.js';
import taymarj from '../images/tmjLogo.png'
import Footer from '../components/footer.js';



const Home = () => {
    const [mobile, setMobile] = useState(true);

    // Mobile Detection
    useEffect(() => {
        function handleResize() {
            const notMobile = window.innerWidth <= 718;
            setMobile(!notMobile);

        }

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [mobile]);

    const sectionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!sectionRef.current) return; // Ensure sectionRef is not null

            const rect = sectionRef.current.getBoundingClientRect();
            if (rect.top < window.innerHeight) {
                sectionRef.current.classList.add('fadeIn');
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="h-screen w-screen flex flex-col">
            <div className="absolute z-[101] top-[15px] max-w-[40%] min-w-[125px] my-auto ">
                <a href="/"><img src={taymarj} className=" m-auto pl-[25px] max-h-[75px] min-h-[70px] " alt="TMJ LOGO" /></a>
            </div>
            <main className="flex-grow w-screen mx-auto z-1">
                {/* Hero Section */}
                <section className="w-full h-[80vh] flex items-center justify-center">
                    <div className="h-full w-full mt-[10vh] relative homeBg bg-cover bg-no-repeat flex flex-col justify-center items-center text-center px-4 sm:px-6 lg:px-12">

                        {/* Main Heading */}
                        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-800 mb-6">
                            Transportation You Can Trust
                        </h1>

                        {/* Text Content */}
                        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-gray-700 font-medium tracking-wide max-w-3xl mb-8">
                            Whether you're a business or individual, we deliver dependable auto transportation solutions that fit your unique needs. From a quick trip across town to a long cross-country journey, we combine professionalism with a personal touch—making every mile count at a price you’ll love.
                        </p>
                        <p>For DSP services please see our <a href="/businessinfo" class="hover:text-black text-[#1e42ee] underline">Delivery Service Partners page</a></p>


                        {/* Buttons Container */}
                        <div className="flex mt-[25px] flex-row gap-8">
                            <a href="/quote">
                                <button className="px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-800 text-white text-lg font-semibold rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105">
                                    Get a Quote
                                </button>
                            </a>
                            <a href="/services">
                                <button className="px-8 py-4 bg-gray-100 text-gray-800 text-lg font-semibold rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105">
                                    Learn More
                                </button>
                            </a>
                        </div>

                        {/* Decorative Image */}
                        {/* <img
                            src={pngegg}
                            alt="Decorative Element"
                            className="absolute bottom-[-16px] w-[10%] min-w-[80px] max-w-[150px] animate-drive right-0 m-4 shadow-lg"
                        /> */}
                    </div>
                </section>

                {/* Social Media Section */}
                <section
                    className="h-[20vh] flex justify-around shadow-lg border-[15px] border-black box-border"
                    style={{ backgroundImage: `url(${lightStrip})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                    <ul className="w-full items-center flex text-2xl sm:text-3xl lg:text-4xl justify-around space-x-4">
                        <li>
                            <a
                                href="https://www.instagram.com/tmjtransport"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-gray-400"
                            >
                                <i className="fab igShareBtn fa-instagram social-icon"></i>
                            </a>
                        </li>
                        <li><p className='text-white'>CONNECT</p></li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/taymar-j-transportation-llc/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-[#3b5898c8] transition-colors"
                            >
                                <i className="fab fa-brands fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li><p className='text-white tracking-widest'>WITH US</p></li>
                        <li>
                            <a
                                href="https://www.facebook.com/profile.php?id=61558049060467"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-[#3b5898b3] transition-colors"
                            >
                                <i className="fab fa-facebook social-icon"></i>
                            </a>
                        </li>
                    </ul>
                </section>

                {/* Additional Sections */}
                <section>
                    <CTA />
                </section>
                <section>
                    <Steps />
                </section>
                <section>
                    <Features />
                </section>
                {/* <section>
                    <PricingSection />
                </section> */}
                <Footer></Footer>
            </main>

        </div>
    );
};

export default Home;
